.modal__container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
}
.modal{
    width: 80%;
    height: 90%;
    padding: 20px;
    background:white;
    border-radius:16px;
    box-shadow: darkgrey 3px 3px 3px 3px;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    animation: modal-bg-show 0.3s;


}
@media only screen and (max-width: 1300px) {
    .modal {
        width:700px;
        height:750px;
    }
    .bt {
        width: 30px;
        height: 40px;
        position:absolute;
        left: 4px;
    }
}

.modal__button{
    background-color: #2E3C7E;
    color:white;
    border-radius: 8px;
    border-color: rgba(255, 255, 255, 0);
    height: 30px;
    width:80px;

    cursor: pointer; outline: none;
    box-shadow: 1px 4px 0 rgb(0,0,0,0.5);
}

.topBtn {cursor:pointer;}

.btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #1976D2;
    border-radius: 50px;
    text-align: center;
    box-sizing: border-box;
    color:white;
    transition: background-color 0.3s ease;
}
.btn:hover {
    background: #64B5F6;
    box-sizing: border-box;
    cursor: pointer;
}

.text {
    max-width: 230px;
    padding: 10px;
    height: auto;
    border: 2px solid lightgray;
    border-radius: 8px;
    text-align: left;
    word-wrap: break-word;
}

.text:after {
    content: "";
    border-top: 14px solid lightgray;
    border-left: 25px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 0px solid transparent;
    position: absolute;
    top:5px;
    left:-26px;
}

.text2 {
    max-width: 230px;
    padding:10px;
    height: auto;
    border: 2px solid lightgray;
    border-radius: 8px;
    text-align:left;
    word-wrap: break-word;
}

.text2:after {
    content: "";
    border-top: 14px solid lightgray;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    position: absolute;
    top:5px;
    right: -26px;
}
/*p.close {*/
/*    text-overflow: ellipsis;*/
/*    overflow:hidden;*/
/*    display: -webkit-box;*/
/*    -webkit-line-clamp: 2;*/
/*    -webkit-box-orient: vertical;*/
/*}*/
